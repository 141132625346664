import {MessagePlugin} from "tdesign-vue-next";

let COS = require('./txUp.js')
let cos = new COS({
    SecretId: 'AKIDBKSkzBMWTNDvY6a0JapwyvkT6ZyFPWUL',
    SecretKey: 'saClFar13veGlDYoz33xthP1a2KYfgcT',
    Domain:'tzwsxdcdn.zgwdwl.com',
    Protocol:'https:'
})
let progress = 0

export function up(fileName,file){
    progress = 0
    return new Promise((resolve,reject)=>{
        cos.putObject({
                Bucket: 'gqtw-1312746166',
                Region: 'ap-chongqing',
                Key: 'back/' + fileName,
                Body: file,
                onProgress: function (info) {
                    console.log("[cos.postObject-seccess]",JSON.stringify(info));
                    progress = info.percent * 100
                }
            },
            function (err, data) {
                if(err){
                    MessagePlugin.error('上传失败！'+JSON.stringify(err))
                    resolve(false)
                    return
                }
                console.log("[cos.postObject-err]",err || data);
                resolve(data.Location)
            })
    })
}

export function get_progress(){
    return progress
}
